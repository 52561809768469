<template>
  <BaseContainer>
    <CandidateProfile />
  </BaseContainer>
</template>

<script>
import CandidateProfile from '@components/Candidates/CandidateProfile'

export default {
  components: {
    CandidateProfile
  }
}
</script>
