<template>
  <div>
    <TooltipButton>
      <template v-slot:content>
        <BaseButton
          variant="salmon"
          :loading="inProgress"
          use-small-loader
          :disabled="!hasIncompleteExams"
          @click="unlock()"
        >
          <template slot="iconMiddle">
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5"
            >
              <Unlock fill="none"/>
            </Icon>
          </template>
        </BaseButton>
      </template>
      <template v-slot:tooltip>
        Unlock incomplete tests
      </template>
    </TooltipButton>
  </div>
</template>

<script>
import Unlock from '@components/Icons/Unlock'
import Icon from '@components/Icons/Icon'
import TooltipButton from '@components/TooltipButton'

import organisationCandidatesApi from '@api/organisationCandidates'
import { mapGetters } from 'vuex'

export default {
  components: {
    Unlock,
    Icon,
    TooltipButton
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },
    uuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      inProgress: false
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    hasIncompleteExams() {
      if (!this.candidate.sentExams) {
        return false
      }

      const incompleteExams = this.candidate.sentExams.filter(exam => {
        return !exam.completedAt && exam.started
      })

      if (incompleteExams.length === 0) {
        return false
      }

      console.log('Found incomplete tests', incompleteExams.map(exam => exam.slug))

      return true
    }
  },

  methods: {
    unlock() {
      this.inProgress = true

      organisationCandidatesApi.unlockIncompleteExams(this.organisationId, this.uuid)
        .then(() => {
          this.inProgress = false
          this.$emit('updated')
        })
        .catch(error => {
          this.inProgress = false
          console.error('Could not unlocks tests')
          throw error
        })
    }
  }
}
</script>
