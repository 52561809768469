<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>
          {{ candidate.firstName }} {{ candidate.surname }}
        </BasePageHeading>
      </div>

      <BasePageActions>
        <span class="text-sm text-gray-600 mr-2">{{ candidate.email }}</span>
        <span
          v-if="candidate.sendingEmailFailed"
          class="text-red-500 text-sm mx-2"
        >
          Check email address
        </span>

        <UnlockIncompleteExams
          :candidate="candidate"
          :uuid="candidate.id"
          @updated="candidateUpdated()"
        />

        <DeleteCandidateButton
          :candidates="[...candidate]"
          @candidates-deleted="candidatesDeleted()"
        />

        <EditCandidateButton
          :candidate="candidate"
          @candidateUpdated="candidateUpdated()"
        />

        <DownloadCandidateProfileButton
          :candidate="candidate"
        />
      </BasePageActions>
    </BasePageHeader>

    <BaseWrapper>
      <!-- <SentExamsTable
        v-if="candidate.attempts.length === 0 && candidate.sentExams.length"
        :candidate="candidate"
      /> -->

      <ul class="space-y-6">
        <li
          v-for="(exam, index) in examsSortedByAttemptDate"
          :key="index"
        >
          <BaseCard
            no-padding
          >
            <CandidateExamAndResult
              :candidate="candidate"
              :exam="exam"
              :index="index"
            />
          </BaseCard>
        </li>
      </ul>

      <!-- @NB Guy asked to remove on 2022-09-27 -->
      <!-- <SentExamsTable
        v-if="candidate.sentExams.length && candidate.attempts.length !== 0"
        :candidate="candidate"
        class="mt-8"
      /> -->

      <div
        v-if="hasLockedAttempts"
        class="mx-6 my-2"
      >
        <p class="text-sm">
          You may need to upgrade to see some results.
          <router-link
            :to="{ name: 'results' }"
            class="text-secondary"
          >
            Check the results page
          </router-link>
        </p>
      </div>
    </BaseWrapper>
    <BaseWrapper>
      <EmptyExamResult
        v-if="candidate.attempts.length === 0 && candidate.sentExams.length === 0"
        :candidate-id="candidate.id"
      />
      <BaseCard v-else-if="candidate.attempts.length === 0 && candidate.sentExams.length > 0">
        <p class="text-center">
          {{ candidate.firstName }} is currently taking the assessment
          <br>
          Their results will show here once they’ve completed a test
        </p>
      </BaseCard>
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import CandidateExamAndResult from '@components/Candidates/CandidateExamAndResult'
import DeleteCandidateButton from '@components/Candidates/DeleteCandidateButton'
import DownloadCandidateProfileButton from '@components/Candidates/DownloadCandidateProfileButton'
import UnlockIncompleteExams from '@components/Candidates/UnlockIncompleteExams'
import EditCandidateButton from '@components/Candidates/EditCandidateButton'
import Loader from '@components/Loader'
import EmptyExamResult from '@components/Candidates/EmptyExamResult'
// import SentExamsTable from '@components/Candidates/SentExamsTable'

import organisationCandidatesApi from '@api/organisationCandidates'
import { mapGetters } from 'vuex'
import states from '@api/states'

export default {
  components: {
    CandidateExamAndResult,
    DeleteCandidateButton,
    DownloadCandidateProfileButton,
    UnlockIncompleteExams,
    EditCandidateButton,
    Loader,
    EmptyExamResult
    // SentExamsTable
  },

  data() {
    return {
      states,

      candidate: null
    }
  },

  page() {
    return {
      title: this.candidateName
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name',
      organisationId: 'employers/organisationId',
      haveExamsLoaded: 'exams/haveExamsLoaded',
      exams: 'organisations/exams'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        },
        {
          name: 'Candidates',
          to: 'candidates'
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      // @TODO 2022-08-25 Add error state
      // if (this.error) {
      //   return states.ERROR
      // }
      if (!this.candidate) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {Array}
     */
    examsSortedByAttemptDate() {
      if (!this.exams) {
        return []
      }

      let examsAndAttempts = []

      this.exams.forEach(exam => {
        this.candidate.attempts
          .filter(attempt => attempt.examSlug === exam.slug)
          .forEach(attempt => {
            examsAndAttempts.push({
              ...exam,
              attempt: attempt
            })
          })
      })

      const more = examsAndAttempts.sort((a, b) => {
        return Object.entries(b.attempt).length !== 0 ? 1 : -1
      })

      return more.sort((a, b) => {
        return new Date(b.attempt.createdAt) - new Date(a.attempt.createdAt)
      })
    },

    /**
     * @return {string}
     */
    candidateName() {
      if (!this.candidate) {
        return null
      }
      return this.candidate.firstName + ' ' + this.candidate.surname
    },

    /**
     * @return {Boolean}
     */
    hasLockedAttempts() {
      if (!this.candidate.attempts) {
        return false
      }
      return this.candidate.attempts.find(attempt => {
        return !attempt.unlockedAt
      })
    }
  },

  created() {
    this.fetchCandidate()

    if (!this.haveExamsLoaded) {
      this.$store.dispatch('exams/getExams', this.token)
    }
  },

  methods: {
    /**
     * Fetch the candidate based on the candidate id
     */
    fetchCandidate() {
      this.candidate = null

      return organisationCandidatesApi.candidate(this.organisationId, this.$route.params.id)
        .then(candidate => {
          this.candidate = candidate
        })
        .catch(error => {
          this.candidate = null
          throw error
        })
    },

    /**
     * A candidate has been deleted
     */
    candidatesDeleted() {
      this.$router.push({ name: 'candidates' })
    },

    /**
     * A candidate has been deleted
     */
    candidateUpdated() {
      this.fetchCandidate()
    }
  }
}
</script>
