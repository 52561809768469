<template>
  <div v-if="examHasAttemptAndIsUnlocked">
    <AptitudeExamResults
      v-if="hasTakenScoreExam"
      :icon="dynamicIcon"
      :candidate="candidate"
      :exam="exam"
      :index="index"
    />

    <DrivesExamResults
      v-if="hasTakenDrivesExam"
      :icon="dynamicIcon"
      :exam="exam"
    />

    <PersonalityExamResults
      v-if="hasTakenPersonalityExam"
      :icon="dynamicIcon"
      :exam="exam"
    />
  </div>
  <div
    v-else
    class="p-6 text-center"
  >
    <p class="my-4">
      We’re unable to show you this test result as it’s locked
    </p>
    <p class="my-4">
      <BaseButton
        v-if="isPAYG"
        :to="{ name: 'settings-payg-plan' }"
      >
        Upgrade to unlock
      </BaseButton>
      <BaseButton
        v-else-if="!isPAYG"
        :to="{ name: 'settings-plan' }"
      >
        Upgrade to unlock
      </BaseButton>
    </p>
  </div>
</template>

<script>
import AptitudeExamResults from '@components/Candidates/AptitudeExamResults'
import DrivesExamResults from '@components/Candidates/DrivesExamResults'
import PersonalityExamResults from '@components/Candidates/PersonalityExamResults'

import { examIcons } from '@data/examIcons'
import { mapGetters } from 'vuex'
import camelCase from 'lodash/camelCase'

export default {
  components: {
    AptitudeExamResults,
    DrivesExamResults,
    PersonalityExamResults
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    },
    index: {
      type: Number
    }
  },

  data() {
    return {
      examIcons
    }
  },

  computed: {
    ...mapGetters({
      isPAYG: 'organisations/isPAYG'
    }),

    /**
     * @return {Boolean}
     */
    examHasAttemptAndIsUnlocked() {
      return (Object.entries(this.exam.attempt).length !== 0) && this.exam.attempt.unlockedAt
    },

    /**
     * @return {Boolean}
     */
    hasTakenScoreExam() {
      return this.exam.attempt.scorePercentage !== null
    },

    /**
     * @return {Boolean}
     */
    hasTakenPersonalityExam() {
      return (this.exam.attempt.examType === 'personality') && (this.exam.attempt.result === null)
    },

    /**
     * @return {Boolean}
     */
    hasTakenDrivesExam() {
      return this.exam.attempt.examSlug.includes('drives')
    },

    /**
     * @return {Boolean}
     */
    hasTakenCustomExam() {
      return !!this.exam.organisationId
    },

    /**
     * @return {Object}
     */
    dynamicIcon() {
      if (!this.exam.icon) {
        if (!(this.exam.slug in this.examIcons)) {
          return () => import('@components/Icons/Exams/Puzzle')
        }
        return () => import('@components/Icons/' + this.examIcons[this.exam.slug])
      }
      // Convert from lebab-case to UpperCamelCase
      let icon = camelCase(this.exam.icon)
      icon = icon.charAt(0).toUpperCase() + icon.slice(1)
      return () => import('@components/Icons/Exams/' + icon)
    }
  }
}
</script>
