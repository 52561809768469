<template>
  <div class="bg-white w-full">
    <div class="p-10 sm:flex-shrink-0 max-w-sm text-center mx-auto">
      <Percentages />
      <h2 class="text-3xl mt-6">
        It's a bit empty here
      </h2>
      <p class="text-gray-600 mb-6">
        Get started by sending some tests
      </p>
      <!-- <SendTestsButton
        :candidate-id="candidateId"
      /> -->
    </div>
  </div>
</template>

<script>
import Percentages from '@components/Vectors/Percentages'
// import SendTestsButton from '@components/Candidates/SendTestsButton'

export default {
  components: {
    Percentages
    // SendTestsButton
  },

  props: {
    candidateId: {
      type: String,
      default: null
    }
  }
}
</script>
